import callAPI from '../lib/callAPI'
import callRawAPI from '../lib/callRawAPI'

const CART_TOKEN_NAME = 'upnorth-shop-cart-token'

const cartInitialState = {
  initialized: false,
  oldtoken: null,
  token: null,
  disabled: false,
  cart: null,
  checkout: null,
  adding: null,
  updating: null,
  removing: null,
  addingvoucher: null
}

class CartProvider {
  use(dispatch, store) {
    this.store = store
    this.dispatch = dispatch
  }

  async close() {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.close')

    await this.dispatch((s) => {
      s.cart.disabled = false
      s.cart.token = null
      s.cart.oldtoken = null
      s.cart.adding = null
      s.cart.updating = null
      s.cart.removing = null
      s.cart.addingvoucher = null
      s.cart.removingvoucher = null
      s.cart.cart = null
      s.cart.checkout = null
      return s
    })
  }

  async clear() {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.clear')

    if (typeof localStorage !== undefined) {
      localStorage.removeItem(CART_TOKEN_NAME)
    }
    this.onCartError()
  }

  async refresh() {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.refresh')

    if (typeof localStorage !== undefined) {
      this.store.cart.token = localStorage.getItem(CART_TOKEN_NAME)
      if (this.store.cart.token && this.store.cart.token.length > 0) {
        const cart = await callAPI(this.store, this.store.app.endpoints.my_cart)
        if (cart) this.onCartUpdated(cart)
        const result = await callRawAPI(this.store, this.store.app.endpoints.checkout)
        if (result) this.onCheckoutUpdated(result)
      }
    }
  }

  async load(clonedState) {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.load')

    if (typeof localStorage !== undefined) {
      try {
        // this is needed as callAPI gets token from store
        this.store.cart.token = localStorage.getItem(CART_TOKEN_NAME)

        if (this.store.cart.token && this.store.cart.token.length > 0) {
          const cart = await callAPI(this.store, this.store.app.endpoints.my_cart)
          if (cart) {
            if (this.store.cart.token !== cart.token) {
              localStorage.setItem(CART_TOKEN_NAME, cart.token)
              this.store.cart.token = cart.token
            }

            clonedState.cart.initialized = true
            clonedState.cart.disabled = false
            clonedState.cart.cart = cart
            clonedState.cart.token = cart.token
          } else {
            throw new Error() // @todo
          }
        }
      } catch (e) {
        clonedState.cart.initialized = true
        clonedState.cart.disabled = false
        clonedState.cart.oldtoken = this.store.cart.token
        if (e && e.data && e.data.closed) {
          localStorage.removeItem(CART_TOKEN_NAME)
        }
      }
    }
    return clonedState
  }

  /* ************************************************** */

  async getOrder(id_order) {
    const result = await callAPI(this.store, {
      href: `/v1/orders/${id_order}`
    })
    return result
  }

  /* ************************************************** */

  async updateCountry(cart, id_country, lang, callback) {
    await this.dispatch((s) => {
      s.cart.disabled = true
      s.cart.updating = 'country'
      return s
    })

    const result = await callAPI(this.store, cart._links.update, {
      id_country: id_country,
      id_address_delivery: 0,
      id_carrier: 0,
      id_lang: lang.id_lang
    })

    if (result && result.id_cart && !result.closed) {
      await this.onCartUpdated(result, callback)
    } else {
      await this.onCartError()
    }
  }

  async updateCarrier(cart, opts, lang, callback) {
    const { id_carrier, id_address_delivery, service_point } = opts

    await this.dispatch((s) => {
      s.cart.disabled = true
      s.cart.updating = 'carrier'
      return s
    })

    const result = await callAPI(this.store, cart._links.update, {
      id_carrier: id_carrier,
      id_address_delivery: id_address_delivery,
      service_point: service_point,
      id_lang: lang.id_lang,
      step_shipping: 1
    })

    if (result && result.id_cart && !result.closed) {
      await this.onCartUpdated(result, callback)
    } else {
      await this.onCartError()
    }
  }

  async updateGiftWrapping(cart, gift, message, lang, callback) {
    await this.dispatch((s) => {
      s.cart.disabled = true
      s.cart.updating = 'giftwrapping'
      return s
    })

    const result = await callAPI(this.store, cart._links.update, {
      gift: gift,
      gift_message: message,
      id_lang: lang.id_lang,
      step_giftwrapping: 1
    })

    if (result && result.id_cart && !result.closed) {
      await this.onCartUpdated(result, callback)
    } else {
      await this.onCartError()
    }
  }

  async updatePayment(checkout, payment, id_address_invoice, lang, callback) {
    await this.dispatch((s) => {
      s.cart.disabled = true
      s.cart.updating = 'payment'
      return s
    })

    const result = await callAPI(this.store, checkout._links.update, {
      id_payment: payment,
      id_address_invoice: id_address_invoice,
      id_lang: lang.id_lang,
      step_payment: 1
    })

    if (result) {
      await this.onCheckoutUpdated(result, callback)
    } else {
      await this.onCartError()
    }

    return result
  }

  /* ************************************************** */

  async addItem(sku, quantity, lang, extra, callback) {
    await this.dispatch((s) => {
      s.cart.disabled = true
      s.cart.adding = sku.id_sku
      return s
    })

    let cart = null
    try {
      quantity = quantity || 1
      cart = await callAPI(this.store, sku._links.add_to_cart, {
        quantity: quantity,
        id_lang: lang.id_lang,
        id_giftlist_product: sku.id_giftlist_product ? sku.id_giftlist_product : null,
        amount: extra && extra.amount ? extra.amount : 0,
        message: extra && extra.message ? extra.message : null
      })

      if (cart && cart.id_cart && !cart.closed) {
        await this.onCartUpdated(cart, callback)

        if (window && window.ga) {
          window.ga('ec:addProduct', {
            'id': sku.id_sku,
            'name': sku.name_en, // @todo use language or not?
            // 'category': sku.category,
            'brand': sku.manufacturer_name,
            'variant': sku.combination_en, // @todo use language or not?
            'price': Number(sku.sellPrice).toFixed(2),
            'coupon': sku.id_giftlist_product ? `GIFTLIST_${sku.id_giftlist_product}` : '',
            'quantity': quantity
          })
          window.ga('ec:setAction', 'add')
          window.ga('send', 'event', 'UX', 'click', 'add to cart')
        }
      } else {
        await this.onCartError()
      }
      return null
    } catch (e) {
      await this.dispatch((s) => {
        s.cart.disabled = false
        s.cart.adding = null
        return s
      })

      return { name: e.type, data: e.data }
    }
  }

  async updateItem(item, quantity, lang, callback) {
    if (quantity <= 0) {
      await this.removeItem(item, lang, callback)
    } else {
      try {
        await this.dispatch((s) => {
          s.cart.disabled = true
          s.cart.updating = item.id
          return s
        })

        quantity = quantity || 1
        const cart = await callAPI(this.store, item._links.update, {
          quantity: quantity,
          id_lang: lang.id_lang
        })

        if (cart && cart.id_cart && !cart.closed) {
          await this.onCartUpdated(cart, callback)

          if (window && window.ga) {
            window.ga('ec:addProduct', {
              'id': `${item.id_product}-${item.id_product_attribute}`,
              'name': item.name_en, // @todo use language or not?
              // 'category': item.category,
              'brand': item.manufacturer_name,
              'variant': item.combination,
              'price': Number(item.sellPrice_tax_incl).toFixed(2),
              'quantity': quantity
            })
            window.ga('ec:setAction', +item.quantity - +quantity < 0 ? 'add' : 'remove')
            window.ga('send', 'event', 'UX', 'click', 'update cart item')
          }
        } else {
          await this.onCartError()
        }

        return null
      } catch (e) {
        await this.dispatch((s) => {
          s.cart.disabled = false
          s.cart.updating = null
          return s
        })

        return { name: e.type, data: e.data }
      }
    }
  }

  async removeItem(item, lang, callback) {
    await this.dispatch((s) => {
      s.cart.disabled = true
      s.cart.removing = item.id
      return s
    })

    const cart = await callAPI(this.store, item._links.remove)

    if (cart && cart.id_cart && !cart.closed) {
      await this.onCartUpdated(cart, callback)

      if (window && window.ga) {
        window.ga('ec:addProduct', {
          'id': `${item.id_product}-${item.id_product_attribute}`,
          'name': item.name_en, // @todo use language or not?
          // 'category': item.category,
          'brand': item.manufacturer_name,
          'variant': item.combination,
          'price': Number(item.sellPrice_tax_incl).toFixed(2),
          'quantity': item.quantity
        })
        window.ga('ec:setAction', 'remove')
        window.ga('send', 'event', 'UX', 'click', 'remove from cart')
      }
    } else {
      await this.onCartError()
    }
  }

  /* ************************************************** */

  async addVoucher(cart, code, callback) {
    await this.dispatch((s) => {
      s.cart.disabled = true
      s.cart.addingvoucher = true
      return s
    })

    try {
      cart = await callAPI(this.store, cart._links.addvoucher, {
        code: code
      })

      if (cart && cart.id_cart && !cart.closed) {
        await this.onCartUpdated(cart, callback)
      } else {
        await this.onCartError()
      }
    } catch(e) {
      await this.dispatch((s) => {
        s.cart.disabled = false
        s.cart.addingvoucher = null
        return s
      })
      return e.type
    }
  }

  async removeVoucher(rule, callback) {
    await this.dispatch((s) => {
      s.cart.disabled = true
      s.cart.removingvoucher = rule.id_cart_rule
      return s
    })

    try {
      const cart = await callAPI(this.store, rule._links.remove)

      if (cart && cart.id_cart && !cart.closed) {
        await this.onCartUpdated(cart, callback)
      } else {
        await this.onCartError()
      }
    } catch(e) {
      await this.dispatch((s) => {
        s.cart.disabled = false
        s.cart.removingvoucher = null
        return s
      })
      return e.type
    }
  }

  /* ************************************************** */

  async handleCheckout(result, lang, navigate) {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.handleCheckout')

    const { pages } = require('../config/pages.json')
    const step = pages.find(i => i.id === 'checkout')
    const stepCart = pages.find(i => i.id === 'cart')
    const stepGuest = pages.find(i => i.id === 'checkout-step-guest')
    const stepShipping = pages.find(i => i.id === 'checkout-step-shipping')
    // const stepGiftWrapping = pages.find(i => i.id === 'checkout-step-giftwrapping')
    const stepPayment = pages.find(i => i.id === 'checkout-step-payment')

    if (result) {
      if (result.changed) {
        navigate(stepCart[`path_${lang.suffix}`])
      }
      else if (result.actions) {
        if (process.env.GATSBY_DEBUG) console.log('handleCheckout')
        if (result.actions.includes('IDENTIFICATION')) {
          navigate(step[`path_${lang.suffix}`])
        } else if (result.actions.includes('INFO')) {
          navigate(stepGuest[`path_${lang.suffix}`])
        } else if (result.actions.includes('CARRIER')) {
          navigate(stepShipping[`path_${lang.suffix}`])
        } else if (result.actions.includes('INVOICE_ADDRESS')) {
          navigate(stepPayment[`path_${lang.suffix}`])
        } else if (result.actions.includes('DELIVERY_ADDRESS')) {
          navigate(stepShipping[`path_${lang.suffix}`])
        } else if (result.actions.length === 0) {
          navigate(stepPayment[`path_${lang.suffix}`])
        }
      }
    }
  }

  async setCheckoutIdentification(lang, navigate) {
    const result = await callAPI(
      this.store,
      this.store.app.endpoints.checkout_identification
    )

    this.onCheckoutUpdated(result, () => {
      const { pages } = require('../config/pages.json')
      const stepCart = pages.find(i => i.id === 'cart')
      const stepGuest = pages.find(i => i.id === 'checkout-step-guest')
      const stepShipping = pages.find(i => i.id === 'checkout-step-shipping')

      if (result) {
        if (result.changed) {
          navigate(stepCart[`path_${lang.suffix}`])
        }
        else if (result.actions) {
          if (process.env.GATSBY_DEBUG) console.log('setCheckoutIdentification')
          if (result.actions.includes('IDENTIFICATION')) {
            navigate(stepGuest[`path_${lang.suffix}`])
          } else if (result.actions.includes('CARRIER')) {
            navigate(stepShipping[`path_${lang.suffix}`])
          } else {
            // @todo show error ?
          }
        }
      } else {
        // @todo show error ?
      }
    })
  }

  async initCheckout(lang, navigate, excludeDefault) {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.initCheckout')

    const { pages } = require('../config/pages.json')
    const step = pages.find(i => i.id === 'checkout')
    const stepCart = pages.find(i => i.id === 'cart')
    const stepGuest = pages.find(i => i.id === 'checkout-step-guest')
    const stepShipping = pages.find(i => i.id === 'checkout-step-shipping')
    // const stepGiftWrapping = pages.find(i => i.id === 'checkout-step-giftwrapping')
    const stepPayment = pages.find(i => i.id === 'checkout-step-payment')

    const result = await this.getCheckout()
    if (result) {
      if (result.changed) {
        navigate(stepCart[`path_${lang.suffix}`])
      }
      else if (result.actions) {
        if (process.env.GATSBY_DEBUG) console.log('initCheckout')
        if (result.actions.includes('IDENTIFICATION')) {
          if (excludeDefault) {
            navigate(stepGuest[`path_${lang.suffix}`])
          } else {
            navigate(step[`path_${lang.suffix}`])
          }
        } else if (result.actions.includes('INFO')) {
          navigate(stepGuest[`path_${lang.suffix}`])
        } else if (result.actions.includes('CARRIER')) {
          navigate(stepShipping[`path_${lang.suffix}`])
        } else if (result.actions.includes('INVOICE_ADDRESS')) {
          navigate(stepPayment[`path_${lang.suffix}`])
        } else if (result.actions.includes('DELIVERY_ADDRESS')) {
          navigate(stepShipping[`path_${lang.suffix}`])
        } else if (result.actions.length === 0) {
          navigate(stepPayment[`path_${lang.suffix}`])
        }
      }
    }
  }

  async getCheckout(callback) {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.getCheckout')

    const result = await callRawAPI(this.store, this.store.app.endpoints.checkout)
    if (result) {
      this.onCheckoutUpdated(result, callback)
    }
    return result
  }

  async getCheckoutStatus(checkout) {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.getCheckoutStatus')

    let result = null
    try {
      result = await callRawAPI(this.store, checkout._links.status)
      if (result) {
        this.onCheckoutUpdated(result)
      }
      return result
    } catch (e) {
      return null
    }
  }

  /* ************************************************** */

  async onCheckoutUpdated(checkout, callback) {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.onCheckoutUpdated')

    await this.dispatch((s) => {
      s.cart.disabled = false
      s.cart.checkout = checkout
      if (!checkout.cart || checkout.cart.closed) {
        s.cart.cart = null
        s.cart.token = null
      } else if (checkout && checkout.cart) {
        s.cart.cart = checkout.cart
      }
      return s
    })

    if (callback) callback()
  }

  async onCartUpdated(cart, callback) {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.onCartUpdated')

    await this.dispatch((s) => {
      s.cart.disabled = false
      s.cart.adding = null
      s.cart.updating = null
      s.cart.removing = null
      s.cart.addingvoucher = null
      s.cart.removingvoucher = null
      s.cart.cart = cart
      s.cart.token = cart.token
      return s
    })

    if (typeof localStorage !== undefined) {
      localStorage.setItem(CART_TOKEN_NAME, cart.token)
    }

    if (callback) callback()
  }

  async onCartError() {
    if (process.env.GATSBY_DEBUG) console.log('CartProvider.onCartError')

    await this.dispatch((s) => {
      s.cart.disabled = false
      // s.cart.token = null
      // s.cart.oldtoken = null
      s.cart.adding = null
      s.cart.updating = null
      s.cart.removing = null
      s.cart.addingvoucher = null
      s.cart.removingvoucher = null
      s.cart.cart = null
      s.cart.checkout = null
      return s
    })

    if (typeof localStorage !== undefined) {
      localStorage.removeItem(CART_TOKEN_NAME)
    }
  }

  /* ************************************************** */

  getMyAddresses() {
    return callAPI(this.store, this.store.app.endpoints.my_addresses)
  }

  /* ************************************************** */
}

export { CartProvider, cartInitialState }
